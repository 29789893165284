
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

}

body {
  font-family: "Segoe UI", Arial, sans-serif;
  line-height: 1.4;
  background: #f2f3f3;
  color: #444;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  width: 100%;
  height: 50px;
  font-size: large;
  font-weight: bolder;
  text-align: center;
  color: purple;
  background-color: rgb(220, 220, 220);
  margin-bottom: 20px;
 padding-left: 10px; 
}

.headerchart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  width: 100%;
  height: 50px;
  font-size: large;
  font-weight: bolder;
  text-align: center;
  color: blue;
  margin-bottom: 20px;
 padding-left: 10px; 
}
.content{
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}
.heading{
  font-size: xx-large;
  font-weight: bolder;
  margin-bottom: 20px;
}

.form-container {
  margin-left: 25%;
  margin-top: 100px;
  font-size: large;
  padding: 20px;
  width: 50%
}

.form-label {
  display: block;
}
.form-button {
  width: 200px;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
  display: block;
  margin-top: 30px;
  margin-left: 33%;
  font-size: large;
  background-color: rgb(88, 88, 252);
  color: white;
}

.piechartparent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 10px;
}
.piechartchild{
  flex-basis: "45%" /* explanation below */
}
.nav-cont {
  border-bottom: 1px solid grey;
  padding: 10px;
  font-size: large;
  font-weight: 600;
}
.nav-elem-1 {
  font-weight: 600;
  cursor: pointer;
}
.nav-elem-2,
.nav-elem-3,
.nav-elem-4 {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 2px solid grey;
  cursor: pointer;
}

.nav-elem-1.active,
.nav-elem-2.active,
.nav-elem-3.active,
.nav-elem-4.active {
  color: orangered;
}
.app_container {
  width: 90%;
  margin-left: 5%;
  margin-top: 20px;
  padding: 10px;
}

.app2_container {
  width: 90%;
  margin-left: 5%;
  margin-top: 20px;

}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
}
table tr:nth-child(3){border-bottom: solid;}
table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}